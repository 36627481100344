import api from "../api";

import { NewNurse } from "../../interfaces/User";
import { Locale } from "../../interfaces/Locale";
import { NewAnnouncement } from "../../interfaces/Announcement";

const baseUrl = "notification/announcements";

class Announcements {
  listOfReaders = id_announcement => {
    return api.get(`${baseUrl}/who-read-it/`, {
      params: {
        is_expired: false,
        model: "nurses.nurses",
        id: id_announcement
      }
    });
  };
}

const announcements = new Announcements();
export default announcements;
