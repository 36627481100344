import { monthNames } from "./names";

export const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day: number = date.getDate();
  const year: number = date.getFullYear();
  const monthNumber: number = date.getMonth();
  const month: string = monthNames[monthNumber];

  return `${day} de ${month} de ${year}`
}

export const formatDayDateHour = (dateStr: string): string => {
  const date = new Date(dateStr);
  const dayOfWeek = date.toLocaleString(
    'es-CL', {weekday: 'long'}
  );
  const day: number = date.getDate();
  const year: number = date.getFullYear();
  const monthNumber: number = date.getMonth();
  const month: string = monthNames[monthNumber];
  const hoursAndMinutes = date.getHours() + ':' + String(date.getMinutes()).padStart(2, "0");

  return `${dayOfWeek} ${day} de ${month} ${year} - ${hoursAndMinutes}`
}

export const calculateAge = (birth: string): Number => {
  const birthDate = new Date(birth);
  var diff_ms = Date.now() - birthDate.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};
