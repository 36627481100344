import React, { useContext } from "react";
import styled from "styled-components";

// typings
import { Announcement } from "../../utils/interfaces/Announcement";

// api
import nurseService from "../../utils/api/v1/nurseService";
import announcements from "../../utils/api/v1/listOfReadersAnnouncements";

// components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  Box,
  Button,
  CardActions,
  Grid,
  Modal,
  Typography,
} from "@material-ui/core";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDayDateHour } from "../../utils/dates/formatDate";
import ModalContainer from "../Containers/ModalContainer";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FiltersContext } from "../Stores/FilterStore";
import { COUNTRY_TO_CODE } from "../../utils/data/constants";
import moment from "moment/moment";

const AnnouncementInfo = styled.div`
  font-size: 18px;
  color: #7b7b7b;
  width: full;
  text-transform: capitalize;
`;

const AnnouncementTitle = styled.div`
  font-size: 20px;
  color: #4f4f4f;
  margin: 15px 0px;
`;

const AnnouncementBody = styled.div`
  font-size: 18px;
  color: #4f4f4f;
  margin: 5px 0px;
  white-space: pre-wrap;
`;

const AnnouncementSentTo = styled.div`
  font-size: 18px;
  color: #4f4f4f;
  margin: 20px 0px 0px 0px;
  white-space: pre-wrap;
`;

const ReadersName = styled.span`
  font-size: 16px;
`;

const ReadersDate = styled.span`
  font-size: 14px;
  text-align: "right";
`;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function AnnouncementCard({
  id,
  user_fullname,
  created_at,
  title,
  message,
  can_delete,
  nurses,
  nurses_fullnames,
}: Announcement) {
  const [open, setOpen] = React.useState(false);
  // @ts-ignore
  const [filters] = useContext(FiltersContext);
  const [nursesWhoReadit, setNursesWhoReadit] = React.useState([]);
  const [nursesActive, setNursesActive] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleOpen = async () => {
    setOpen(true);
    let response_nurses = await nurseService.fetchActiveNurses(
      COUNTRY_TO_CODE[filters.country]
    );
    let response_announcements = await announcements.listOfReaders(id);
    let active_nurses = response_nurses.data.data;
    if (nurses.length > 0) {
      active_nurses = active_nurses.filter((nurse) => nurses.includes(nurse.id))
    }
    let nurses_who_read_it = response_announcements.data.users;
    let list_of_id_readers = nurses_who_read_it.map((value) => value.user);
    setNursesWhoReadit(response_announcements.data["users"]);
    let pending_to_read = active_nurses.filter((nurse) => {
      return !list_of_id_readers.includes(nurse.user_id);
    });
    console.log(pending_to_read)
    setNursesActive(pending_to_read);
  };
  const handleClose = () => {
    setOpen(false);
    setNursesActive([]);
    setNursesWhoReadit([]);
  };
  const handleDeleteAnnouncement = async (
    announcementId: string
  ): Promise<void> => {
    Swal.fire({
      icon: "warning",
      title: "¿Estás seguro de que quieres eliminar este anuncio?",
      confirmButtonText: "Si, eliminar",
      showCancelButton: true,
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await nurseService.deleteNurseAnnouncement(
            announcementId
          );
          if (res.data) {
            Swal.fire({
              icon: "success",
              title: "Anuncio eliminado",
            });
            window.location.reload();
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No puedes elminar un anuncio que no es tuyo",
          });
        }
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContainer>
            <Grid>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "500px",
                  overflow: "hidden",
                  // @ts-ignore
                  overflowY: "scroll",
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label={`Quienes leyeron (${nursesWhoReadit.length})`}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={`Por leer (${nursesActive.length})`}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {nursesWhoReadit.length === 0 && (
                    <Grid> Por el momento nadie lo ha leído...</Grid>
                  )}
                  {nursesWhoReadit.map((announcement, index) => (
                    <Grid container>
                      <Grid item xs={8}>
                        {announcement.user_fullname}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{ textAlign: "right", fontSize: "0.9rem" }}
                      >
                        {moment(announcement.created_at).format("DD/MM/YYYY")}
                      </Grid>
                    </Grid>
                  ))}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {nursesActive.length === 0 && (
                    <Grid>
                      Wow... no hay nadie que tenga pendiente la lectura...
                    </Grid>
                  )}
                  {nursesActive.map((announcement, index) => (
                    <Grid>
                      {announcement.names}, {announcement.last_names}
                    </Grid>
                  ))}
                </TabPanel>
              </Box>
            </Grid>
          </ModalContainer>
        </Modal>
        <Grid container spacing={1}>
          <Grid item sm={12} md={6}>
            <AnnouncementInfo>{user_fullname}</AnnouncementInfo>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            style={{ textAlign: "right" }}
            onClick={handleOpen}
          >
            <Button
              variant="text"
              endIcon={<FontAwesomeIcon icon={faCircleInfo} />}
            >
              <label>{formatDayDateHour(created_at)}</label>
            </Button>
          </Grid>
        </Grid>
        <AnnouncementTitle>{title}</AnnouncementTitle>
        <AnnouncementBody>{message}</AnnouncementBody>
        {nurses_fullnames?.length === 0 && <AnnouncementSentTo>Enviado a: Todos/as</AnnouncementSentTo>}
        {nurses_fullnames?.length > 0 && <AnnouncementSentTo>Enviado a: {nurses_fullnames?.join(", ")}</AnnouncementSentTo>}
      </CardContent>
      {can_delete && (
        <CardActions>
          <Button
            size="small"
            color="secondary"
            onClick={() => handleDeleteAnnouncement(id)}
          >
            Eliminar
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default React.memo(AnnouncementCard);
