import React, { useContext } from "react";

import styled from "styled-components";

// components
import { FiltersContext } from "../../components/Stores/FilterStore";
import { COUNTRIES } from "../../utils/data/constants";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";

const CountrySelect = styled(Select)`
  width: 200px;
  margin-bottom: 1rem;
`;

const Spaced = styled.div`
  margin: 0.5rem 1rem;
`;

const locationSelectProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

function CountryFilter() {
  // @ts-ignore
  const [filters, filtersDispatch] = useContext(FiltersContext);

  return (
    <Spaced>
      <FormControl margin="normal">
        <InputLabel>País</InputLabel>
        <CountrySelect
          autoWidth
          value={filters?.country}
          onChange={(e) => {
            filtersDispatch({
              type: "UPDATE_COUNTRY",
              payload: e.target.value as string,
            });
          }}
          MenuProps={locationSelectProps}
        >
          {COUNTRIES.map((country: string) => (
            <MenuItem value={country} key={country}>
              {country}
            </MenuItem>
          ))}
        </CountrySelect>
      </FormControl>
    </Spaced>
  );
}

export default CountryFilter;
